import '../sass/components/login.scss'
import {useAuth0} from "@auth0/auth0-react";
import {Link} from "react-router-dom";
import LogoImage from './LogoImage';

function Login () {

    const { loginWithRedirect } = useAuth0();
    return (
        <div className="page login-page">
            <LogoImage className="login-logo-img" imageStyle={'big'}/>
            <h6 className='login-page-title'>{process.env.REACT_APP_WELCOME_MESSAGE}<br />By Open Imagination</h6>
            <button className='login-btn' onClick={ () => loginWithRedirect()}>Log in</button>
            {process.env.REACT_APP_PROSPECTS === "1" &&
                <a className="trade-account" href="/trade-account" target="_self">
                    Apply for a Trade Account
                </a>
            }
        </div>
    )
}
export default Login
