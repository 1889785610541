import React, {Fragment, useContext} from "react";

import 'reactjs-popup/dist/index.css';

import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchOneProduct} from "../apiRequests/api";
import Loading from "./Loading";
import wineWhite from "../assets/Wine-white.png";
import wineWhiteGrey from "../assets/Wine-white-grey.png";
import wineRed from "../assets/Wine-red.png";
import "../sass/components/product-item.scss";
import "../sass/components/update-cart-btn.scss";
import {useAuth0} from "@auth0/auth0-react";
import {updateCart} from "../utils/cartTools";
import {useDispatch, useSelector} from "react-redux";
import {productFields} from "../settings";
import {titleField} from "../settings";
import {SigninContext} from "../contexts/SigninContext";
import Image from "./Image";
import { resolveAvailabilityMsg } from '../utils/extraTools';

export default function ProdItem(props) {
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    const [cases, setCases] = useState(props.cases ? props.cases : '')
    const [bottles, setBottles] = useState(props.bottles ? props.bottles : '')
    const [product, setProduct] = useState({})
    const {userName, setUserName, setDisplayProfile} = useContext(SigninContext)
    if (process.env.REACT_APP_STOCK_CONTROL === '1') {
        var StockTBottles = +product.StockCases * +product.CaseSize + +product.StockBottles
        var StockTCases = +product.StockCases
    } else {
        var StockTBottles = 9999999
        var StockTCases = 9999999
    }

    const [isLoading, setLoading] = useState(true)
    const [cartUpdateStyles, setCartUpdateStyles] = useState('')
    const [cartAdd, setCartAdd] = useState('')
    const {id} = useParams()
    var prodCode = {
        ProductCode: id ? id : props.id
    }
    const {getIdTokenClaims} = useAuth0();
    const [customLabel, setCustomLabel] = useState(props.customLabel ? props.customLabel : ''); // Initialize with an empty string or the prop value


    // Change data regarding to product depending on page
    async function loadProduct() {
        if (id || props.id) {
            const token = await getIdTokenClaims()
            const singleProduct = (await fetchOneProduct(prodCode, token.__raw)).mainResult
            setProduct(singleProduct)
            setLoading(false)
            if (!props.id) cart.items.forEach(el => {
                if (el.Code == singleProduct.Code) {
                    setCases(el.CasesQty)
                    setBottles(el.BottlesQty)
                }
            })
        } else {
            setProduct(props.product)
            cart.items.forEach(el => {
                if (el.Code == props.product.Code) {
                    setCases(el.CasesQty)
                    setBottles(el.BottlesQty)
                }

            })
        }
        setLoading(false)
    }

    function handleKeyPress() {

    }
// Use includes method on string
    function addToCartHandler() {
        let currentProduct
        if (id || props.id) {
            currentProduct = product
        } else {
            currentProduct = props.product
        }

        if (cases > 0 || bottles > 0) {
            let newCases
            let newBottles
            let newTotal = +cases * currentProduct.CaseSize + +bottles

            newCases = Math.floor(newTotal / currentProduct.CaseSize)
            newBottles = newTotal % currentProduct.CaseSize

            if (newTotal > StockTBottles) {
                setCases(StockTCases)
                newCases = StockTCases
                setBottles(StockTBottles % currentProduct.CaseSize)
                newBottles = (StockTBottles % currentProduct.CaseSize)
            } else {
                setCases(newCases)
                setBottles(newBottles)
            }

            const newCart = updateCart(currentProduct, cart, 'ADD', newCases, newBottles)
            dispatch({type: "UPDATE_CART", payload: newCart})
            setCartAdd('added')
            setTimeout(() => setCartAdd(''), 3000);
            setCartUpdateStyles('refreshed')
            setTimeout(() => setCartUpdateStyles(''), 500);
        } else {
            const newCart = updateCart(currentProduct, cart, "REMOVE")
            dispatch({type: "UPDATE_CART", payload: newCart})
        }
    }

    useEffect(() => {
        loadProduct()
    }, [userName])

    var imageFile = /[^/]*$/.exec(product.Image1_URL)[0];
    // Get correct image for product.
    var image = process.env.REACT_APP_VINTNER_IMAGES && product.Image1_URL !== "" && !process.env.REACT_APP_URL_TO_IMAGES ? product.Image1_URL : product.Image1_URL !== "" && process.env.REACT_APP_URL_TO_IMAGES ? process.env.REACT_APP_URL_TO_IMAGES + imageFile
        : (product.Type == "White") ? wineWhite
            : (product.Type == "Red") ? wineRed : wineWhiteGrey;

    if (process.env.REACT_APP_UNIT_FLAG !== "1") {
        var Unit = product.CaseSize;
    } else {
        var Unit = "1";
    }

    return (

        <Fragment>
            {id && <>
                <h2 className="product-details-title">Product Details</h2>
            </>}


            <div className="product-list-item_wrapper">
                {process.env.REACT_APP_SHOW_STOCK_WARNING && process.env.REACT_APP_LOW_STOCK_CASES && (+product.StockCases * +product.CaseSize + +product.StockBottles) <= (product.CaseSize * process.env.REACT_APP_LOW_STOCK_CASES) &&
                    <div className="product-message">
                        <i className="fa fa-sharp fa-exclamation-circle"></i> Low Stock
                    </div>
                }
                <div className="product-list-item_left">
                    {process.env.REACT_APP_IMAGES_JSON ?
                        <Image Alt={product[titleField]} ProductCode={product.Code} Placeholder={image}/> :
                        <Link to={`/product-detail/${product.Code}`}>
                            <img
                                src={image}
                                alt={product[titleField]}
                                className="nav-user-profile rounded-circle"
                            />
                        </Link>}
                </div>
                <div className="product-list-item_right">
                    {product.SecondaryDescription !== "" && product[titleField] && product[titleField].trim() !== '' &&
                        <div className='product-list-item-title-link-wrapper'>
                            <Link className='product-list-item-title-link' to={`/product-detail/${product.Code}`}>
                                <div
                                    className='product-list-item-title'>{product[titleField]}</div>
                            </Link>
                        </div>
                    }

                    <div className="product-list-item_right__top">
                        {productFields.map((el, index) => el.name && !el.combined ? (

                                <div key={index} className="code product-list-item_inner">
                                    {el.name === "Code" &&
                                        <>
                                            <i className={`fas fa-icon ${el.iconClass}`}></i>
                                            {product['BinNo'] !== "" && process.env.REACT_APP_BIN === '1' ? (
                                                <p>{product['BinNo']}</p>
                                            ):
                                                <p>{decodeURIComponent(product['Code'])}</p>}
                                        </>
                                    }
                                    {el.name !== "Code" &&
                                        product[el.name] && product[el.name] !== "    " && product[el.name] !== " " &&
                                        <>
                                        <i className={`fas fa-icon ${el.iconClass}`}></i>
                                            <p>{product[el.name]}</p>
                                        </>
                                    }
                                </div>
                            ) : (
                                <div key={index} className="product-list-item_inner">
                                    <i className={`fas fa-icon ${el.iconClass}`}></i>
                                    <p>
                                        {el.combined.map((combinedEl, index) => (

                                            <span
                                                key={index}>{product[combinedEl.name]}
                                                {index < el.combined.length - 1 && el.separator}
                                                {index === el.combined.length - 1 && el.append}
                                            </span>
                                        ))}
                                    </p>
                                </div>
                            )
                        )}
                         
                            <div className="custom-label-section">                                
                                <p>{props.CustomLabel}</p>
                            </div>
                    </div>
                    <div className="product-list-item_right__bottom">
                        <div className="product-list-item_inner_wrapper">
                            {resolveAvailabilityMsg(product).length > 1 ?
                              <p className="product-list-item-input-label text-center">
                                  {resolveAvailabilityMsg(product)}
                              </p>
                              : ''}
                            {process.env.REACT_APP_SHOW_STOCK === '3' && resolveAvailabilityMsg(product, 'on order').length > 1 ?
                              <p className="product-list-item-input-label text-center">
                                  {resolveAvailabilityMsg(product, 'on order')}
                              </p>
                              : ''}
                            <div className="product-list-item_right__bottom__left">
                                {process.env.REACT_APP_UNIT_FLAG !== "1" || product.CaseSize === 1 ? (
                                    <div className="product-list-item_inner input">
                                        <h6 className='product-list-item-label cases'>{product.CustomLabel ?? 'Cases'}</h6>
                                        <div className='product-list-item__qty-row'>
                                            <input type="button" value="-" className="button-minus no_print"
                                                   onClick={() => setCases(cases > 0 && cases - 1)}
                                                   data-field="quantity"
                                            />
                                            <input type="number" id="stock-cases-input" name="stock-cases-input"
                                                   autoComplete="off" max="1" value={cases} placeholder={'0'}
                                                   onChange={(e) => setCases(+e.target.value < 0 ? '' : +e.target.value)}
                                                   min="0" max={StockTCases}
                                            />
                                            <input type="button" value="+" className="button-plus no_print"
                                                   onClick={() => setCases(+cases + 1)} data-field="quantity"
                                            />
                                        </div>
                                    </div>
                               ):('')
                                }
                                {((process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF && product.OnlySellMultiplesOf <= 1 && process.env.REACT_APP_UNIT_FLAG !== "2") || (!process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF && process.env.REACT_APP_UNIT_FLAG !== "2"))  && product.CaseSize > 1  ? (
                                    <div className="product-list-item_inner input">
                                        <h6 className='product-list-item-label bottles'>Bottles</h6>
                                        <div className='product-list-item__qty-row'>
                                            <input type="button" value="-" className="button-minus no_print"
                                                   onClick={() => setBottles(bottles > 0 && bottles - 1)}
                                                   data-field="quantity"
                                            />
                                            <input type="number" id="stock-bottles-input" name="stock-bottles-input"
                                                   autoComplete="off" value={bottles} placeholder={'0'}
                                                   onChange={(e) => setBottles(+e.target.value < 0 ? '' : +e.target.value)}
                                                   min="0" max={StockTBottles}
                                            />
                                            {bottles < StockTBottles &&
                                                <input type="button" value="+" className="button-plus no_print"
                                                       onClick={() => setBottles(+bottles + 1)} data-field="quantity"
                                                />}
                                            {bottles >= StockTBottles &&
                                                <input type="button" value="+" className="button-plus no_print"
                                                       data-field="quantity"
                                                />}
                                        </div>
                                    </div>) : (<></>)
                                }
                            </div>
                        </div>
                        <div className="product-list-item_right__bottom__right">
                            {process.env.REACT_APP_UNIT_FLAG !== "1" &&
                                <div className="product-list-item_inner case-nett">
                                    <h6 className='product-list-item-label'>Case Nett:</h6>
                                    <p>{window.currency}{(Math.round((product.CasePrice * 1000) / 10) / 100).toFixed(2)}</p>
                                </div>
                            }
                            {process.env.REACT_APP_UNIT_FLAG !== "2" && product.OnlySellMultiplesOf <= 1 &&
                                <div className="product-list-item_inner bottle-nett">
                                    <h6 className='product-list-item-label'>Bottle Nett:</h6>
                                    <p>{window.currency}{(Math.round((product.BottleNett * 1000) / 10) / 100).toFixed(2)}</p>
                                </div>
                            }
                            <div className="product-list-item_inner update-cart">
                                {StockTBottles > 0 &&
                                    <button
                                        className={`update-cart-btn update-cart-product-list-table ${cartUpdateStyles}`}
                                        onClick={() => addToCartHandler()}>
                                        <p>Add to Cart</p>&nbsp;
                                        <i className="fas fa-icon fa-shopping-cart"></i>
                                    </button>}
                                {StockTBottles < 1 &&
                                    <button className={`update-cart-btn disabled update-cart-product-list-table`}
                                            disabled>
                                        <p>Out of Stock</p>
                                        <i className="fas fa-icon fa-shopping-cart"></i>
                                    </button>}
                                <div className={`add-to-cart ${cartAdd}`}>{product[titleField]} added to basket
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>

    )
}